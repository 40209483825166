<template>
  <div>
    <Crumbs></Crumbs>
    <div class="mainList">
      <div class="mainList_operation clearfloat">
        <div class="mainList_operation_search">
          <span
            >部门：
            <DeptCascader
              :placeholder="'请选择部门'"
              :deptList="deptList"
              v-model="parameter.deptId"
            />
          </span>
          <span
            >员工名称：
            <el-input
              placeholder="请输入员工名称"
              clearable
              v-model="parameter.userName"
              class="ipt_width"
            >
            </el-input
          ></span>
          <span
            >项目名：
            <el-select
              v-model="parameter.projectId"
              @change="dataUpdate"
              clearable
              filterable
              placeholder="请输入或选择项目名"
              class="ipt_width"
            >
              <el-option
                v-for="(item, index) in projectList"
                :key="index"
                :label="item.projectName"
                :value="item.id"
              >
              </el-option> </el-select
          ></span>
          <!-- <el-input placeholder="请输入项目名称" class="ipt_width" clearable v-model="parameter.projectName">
                  </el-input> -->

          <!-- checkStatus -->
          <span
            >工时类型：
            <el-select
              v-model="parameter.manhourType"
              @change="dataUpdate"
              clearable
              filterable
              placeholder="请选择工时类型"
              class="ipt_width"
            >
              <el-option
                v-for="(item, index) in dictData.manhourType"
                :key="index"
                :label="item.dictName"
                :value="item.dictVal"
              >
              </el-option> </el-select
          ></span>
          <span
            >审核状态：
            <el-select
              v-model="parameter.checkStatus"
              @change="dataUpdate"
              clearable
              filterable
              placeholder="请选择审核状态"
              class="ipt_width"
            >
              <el-option
                v-for="(item, index) in dictData.manhourCheackStatus"
                :key="index"
                :label="item.dictName"
                :value="item.dictVal"
              >
              </el-option> </el-select
          ></span>
          <span
            >工时日期：
            <el-date-picker
              class="date_width"
              v-model="createdDate"
              :pickerOptions="pickerOptions"
              type="daterange"
              range-separator="至"
              start-placeholder="开始日期"
              end-placeholder="结束日期"
            >
            </el-date-picker
          ></span>

          <el-button type="primary" icon="el-icon-search" @click="pageChangeHandler(1)"
            >搜索</el-button
          >
          <!-- <el-button type="primary" icon="el-icon-edit" @click="clear_serach()">清空</el-button> -->
          <el-button type="warning" icon="el-icon-refresh" @click="handleReset">清空</el-button>
          <el-button
            type="primary"
            icon="el-icon-printer"
            v-if="permission(['HOUR_BULU_EXPORT'])"
            @click="exporting()"
            >导出</el-button
          >
          <el-button type="primary" icon="el-icon-left" v-if="status" @click="$router.back()"
            >返回上一页</el-button
          >
        </div>
      </div>
      <div class="mainList_content">
        <div class="mainList_content_sub">
          <el-table
            ref="multipleTable"
            row-key="id"
            border
            :data="tableData"
            :default-expand-all="true"
            tooltip-effect="dark"
            :tree-props="{ children: 'children', hasChildren: 'hasChildren' }"
            height="string"
            v-loading="loading"
            @cell-click="handleCellClick"
          >
            <el-table-column align="center" type="index" label="序号" width="50"></el-table-column>
            <el-table-column
              align="center"
              prop="userName"
              label="填报人"
              width="90"
            ></el-table-column>
            <el-table-column
              align="center"
              prop="departmentName"
              label="所属部门"
              width="110"
            ></el-table-column>
            <el-table-column align="center" prop="workName" label="项目名称" min-width="240">
              <template slot-scope="scope">
                <span v-if="scope.row.manhourType == 0">{{ scope.row.projectName }}</span>
                <span v-if="scope.row.manhourType == 1">{{ scope.row.workName }} </span>
              </template></el-table-column
            >
            <el-table-column
              align="center"
              prop="workContent"
              label="工作内容"
              min-width="240"
            ></el-table-column>
            <el-table-column align="center" prop="checkStatus" label="审批状态" width="150">
              <template slot-scope="scope">
                {{ scope.row.checkStatus.toString() | dict(dictData.manhourCheackStatus) }}
              </template>
            </el-table-column>
            <el-table-column align="center" prop="manhourType" label="有无重要活动" width="110">
              <template slot-scope="scope">
                <span v-if="scope.row.outgoingType == 'HAVE'"
                  ><el-button
                    type="primary"
                    size="mini"
                    plain
                    @click="showOutReportDetail(scope.row)"
                    >查看报告</el-button
                  ></span
                >
                <span v-else>无</span>
              </template>
            </el-table-column>
            <el-table-column align="center" prop="isTraveling" label="是否外勤" width="110">
              <template slot-scope="scope">
                <span v-if="scope.row.isTraveling == 'Y'"
                  ><el-button type="primary" size="mini" plain @click="showOutWorkDetail(scope.row)"
                    >查看报告</el-button
                  ></span
                >
                <span v-else>否</span>
              </template>
            </el-table-column>

            <el-table-column align="center" prop="manhourType" width="110" label="工时类型">
              <template slot-scope="scope">
                {{ scope.row.manhourType.toString() | dict(dictData.manhourType) }}
              </template>
            </el-table-column>

            <el-table-column
              align="center"
              prop="hour"
              label="填报工时"
              width="90"
            ></el-table-column>
            <el-table-column
              align="center"
              prop="auditHour"
              label="审批工时"
              width="90"
            ></el-table-column>
            <el-table-column align="center" prop="manhourDate" label="工时日期" width="110">
              <template slot-scope="scope">
                {{ scope.row.manhourDate | dateFormat }}
              </template>
            </el-table-column>
            <el-table-column
              align="center"
              prop="submitDate"
              label="填报时间"
              width="160"
            ></el-table-column>
            <!-- <el-table-column align="left" prop="checkTime" label="审批时间"></el-table-column> -->
          </el-table>
          <el-pagination
            @size-change="handleSizeChange"
            @current-change="pageChangeHandler"
            :current-page="parameter.pageNow"
            :page-size="parameter.pageSize"
            prev-text="上一页"
            next-text="下一页"
            layout="total, prev, pager, next, slot, jumper"
            :total="parameter.total"
          >
            <span class="el-pagination__jump e_a_pagination">
              <el-input size="mini" v-model.number="pageSize" @blur="handlePageSize"></el-input>
              <span style="padding-top: 1px">条/页</span>
            </span>
          </el-pagination>
        </div>
      </div>
    </div>
    <el-dialog title="工作内容" :visible.sync="workContentDialogVisible" width="40%">
      <div class="dialogContentHeight">{{ workContentDialog }}</div>
    </el-dialog>
    <OutReportDetail
      v-if="isOutReportDetailShow"
      :isOutReportDialogShow.sync="isOutReportDetailShow"
      :outReportData="manhourOutgoing"
    >
    </OutReportDetail>

    <OutWorkDetail
      v-if="isOutWorkDetailShow"
      :isOutWorkDialogShow.sync="isOutWorkDetailShow"
      :outWorkInfo="manhourTraveling"
    />
  </div>
</template>
<script>
import { encrypt, decrypt } from '@/util/jsencrypt'
import { mapState } from 'vuex'

export default {
  components: {
    Crumbs: () => import('@/components/Crumbs.vue'),
    Upload: () => import('@/components/upload/Upload.vue'),
    OutReportDetail: () => import('@/components/hour/out-report-detail.vue'),
    DeptCascader: () => import('@/components/dept/DeptCascader.vue'),
    OutWorkDetail: () => import('@/components/hour/out-work-detail.vue'),
  },
  data() {
    return {
      parameter: {
        pageNow: 1,
        pageSize: 50,
        total: 0,
      },
      pageSize: 0,
      form: {},
      tableData: [],
      loading: false,
      createdDate: [],
      projectId: '',
      userId: '',
      deptId: '',
      status: '',
      deptList: [],
      is_dsz: '',
      projectList: [],
      workContentDialogVisible: false,
      workContentDialog: '',
      dictData: [],
      pickerOptions: {
        onPick: v => {
          if (v.minDate && !v.maxDate) {
            this.$set(this.createdDate, 0, v.minDate.getTime())
            this.$set(this.createdDate, 1, '')
          }
        },
      },

      manhourOutgoing: null, //外出活动报告数据源
      isOutReportDetailShow: false,
      filepath: process.env.VUE_APP_FILEPATH,
      fields: {
        name: 'fileName',
        path: 'filePath',
        type: 'type',
      },
      manhourTraveling: {}, //外勤数据源
      isOutWorkDetailShow: false,
    }
  },
  provide() {
    return {
      re: this.re,
    }
  },
  computed: {
    ...mapState({
      userInfo: state => state.user.userInfo,
    }),
  },
  created() {
    this.pageSize = this.userInfo.pageSizeLog
    this.parameter.pageSize = this.userInfo.pageSizeLog
    this.is_dsz = JSON.parse(localStorage.getItem('userInfo'))
    this.getDictData()
    this.getData()
    this.getProject()
    this.get_DeptList()
  },
  watch: {
    createdDate: {
      deep: true,
      handler: function (val) {
        if (val == null) {
          this.createdDate = []
        }
      },
    },
  },
  methods: {
    getData() {
      this.loading = true
      if (this.createdDate && this.$route.query.createdDate != []) {
        this.parameter.beginDate = new Date(this.createdDate[0]).getTime()
        this.parameter.endDate = new Date(this.createdDate[1]).getTime()
        this.$route.query.createdDate = JSON.stringify(this.createdDate)
      }

      if (this.$route.query.createdDate) {
        this.createdDate = JSON.parse(this.$route.query.createdDate)
      }
      if (this.$route.query.userId) {
        this.status = 1
        this.userId = decrypt(this.$route.query.userId)
        this.parameter.userId = this.userId
      }
      if (this.$route.query.projectId) {
        this.status = 2
        this.projectId = decrypt(this.$route.query.projectId)
        this.parameter.projectId = this.projectId
      }
      if (this.deptId && this.$route.query.deptId) {
        this.status = 3
        this.deptId = decrypt(this.$route.query.deptId)
        // this.parameter.deptId = this.deptId
        // } else if (this.is_dsz.postName == '董事长') {
      } else if (this.is_dsz.weight == 10000) {
        this.deptId = this.is_dsz.deptIdList[0]
        // this.parameter.deptId = this.deptId
        this.is_dsz = ''
      }
      if (this.$route.query.manhourType == 1) {
        this.parameter.manhourType = 1
      }

      this.$api.hour
        .mahhoursByFillList(this.parameter)
        .then(res => {
          this.loading = false
          if (res.data == null) {
            this.tableData = []
          } else {
            this.tableData = res.data.records
          }
          this.parameter.total = res.data.total
        })
        .catch(err => {
          console.log(err)
          this.loading = false
        })
    },

    pageChangeHandler(val) {
      this.parameter.pageNow = val
      this.$refs.multipleTable.bodyWrapper.scrollTop = 0
      this.getData()
    },
    handleSizeChange(val) {
      this.parameter.pageSize = val
      this.parameter.pageNow = 1
      this.getData()
    },
    handleReset() {
      this.createdDate = []
      this.$route.query.createdDate = ''
      this.deptId = ''
      this.parameter = {
        pageNow: 1,
        pageSize: 50,
        total: 0,
        deptId: '',
        userId: this.userId,
        projectId: this.projectId,
      }
      this.getData()
    },
    get_DeptList() {
      this.$api.sysDept
        .getTreeDept()
        .then(res => {
          this.deptList = res.data
          this.$store.commit({
            type: 'business/SET_DEPT_LIST',
            selectDeptList: res.data,
          })
        })
        .catch(err => {
          console.log(err)
        })
    },
    dataUpdate(row) {
      this.$forceUpdate()
    },
    handlePageSize() {
      if (this.userInfo.pageSizeLog == this.pageSize) {
        return
      }
      if (!this.pageSize) {
        this.pageSize = 1
      }
      let obj = { pageSizeLog: this.pageSize }
      this.loading = true
      this.$api.common
        .saveStaffPageSizeLog(obj)
        .then(res => {
          this.loading = false
          this.parameter.pageNow = 1
          this.parameter.pageSize = this.pageSize
          this.userInfo.pageSizeLog = this.pageSize
          localStorage.setItem('userInfo', JSON.stringify(this.userInfo))
          this.getData()
        })
        .catch(err => {
          console.log(err)
          this.loading = false
        })
    },
    getProject() {
      this.$api.project
        .getProjectList({ pageSize: 10000 })
        .then(res => {
          this.projectList = res.data ? res.data : []
        })
        .catch(err => {
          console.log(err)
        })
    },

    // 打开预览工作内容
    handleCellClick(row, column) {
      if (column.label == '工作内容') {
        this.workContentDialog = row.workContent
        this.workContentDialogVisible = true
      }
    },
    getDictData() {
      this.$api.dict
        .listSysDictData('MANHOUR_TYPE', true)
        .then(res => {
          this.dictData.manhourType = res.data
        })
        .catch(err => {
          console.log(err)
        })

      this.$api.dict
        .listSysDictData('MAHHOURS_CHECK_STATUS', true)
        .then(res => {
          this.dictData.manhourCheackStatus = res.data
        })
        .catch(err => {
          console.log(err)
        })
    },

    exporting() {
      if (this.createdDate) {
        this.parameter.beginDate = new Date(this.createdDate[0]).getTime()
        this.parameter.endDate = new Date(this.createdDate[1]).getTime()
      }

      this.$api.hour
        .mahhoursFillExpor(this.parameter)
        .then(res => {
          const date = new Date()
          const year = date.getFullYear().toString()
          const month = (date.getMonth() + 1).toString().padStart(2, '0')
          const day = date.getDate().toString().padStart(2, '0')
          const timestamp = year + month + day
          const fileName = '补录工时明细数据' + timestamp + '.xlsx'
          const blob = new Blob([res], {
            type: 'application/vnd.ms-excel',
          })
          if (navigator.msSaveBlob) {
            navigator.msSaveBlob(blob, fileName)
          } else {
            const link = document.createElement('a')
            link.href = URL.createObjectURL(blob)
            link.download = fileName
            link.click()
            URL.revokeObjectURL(link.href)
          }

          this.$message({
            message: '导出成功!',
            type: 'success',
          })

          this.parameter.beginDate = ''
          this.parameter.endDate = ''
        })
        .catch(e => {})
    },

    showOutReportDetail(row) {
      this.manhourOutgoing = null
      // 需要调接口获取外出活动内容
      this.$api.hour
        .selectManhourOutgoingDetailByManhourId(row.id)
        .then(res => {
          if (res.data) {
            res.data.fileList = res.data.fileList
            this.manhourOutgoing = res.data
            this.isOutReportDetailShow = true
          }
        })
        .catch(err => {
          console.log(err)
        })
    },

    upload(r) {
      this.outReportData.fileList.push(...r)
      this.outReportData.fileErrMsg = ''
    },

    del(index) {
      this.outReportData.fileList = this.outReportData.fileList.filter((item, i) => i != index)
    },
    showOutWorkDetail(row) {
      this.manhourTraveling = {}
      // 需要调接口获取外勤内容
      this.$api.hour
        .selectDetailByManhourId(row.id)
        .then(res => {
          if (res.data) {
            this.manhourTraveling = res.data
            this.isOutWorkDetailShow = true
          }
        })
        .catch(err => {
          console.log(err)
        })
    },
  },
}
</script>
<style scoped lang="scss">
@import '@/styles/config.scss';
.dialogContentHeight {
  height: 250px;
  overflow: auto;
  white-space: pre-wrap;
  padding: 0px 20px;
}
</style>
